#invitatie .card {
   
    color: black;
}

#invitatie .card h4 {
    color: aliceblue;
    text-shadow: 1px 1px 2px rgb(26, 25, 25);
    
}

#img_noi {
    width: 250px;
    border-radius: 100%;
}

#img_nasi {
    width: 250px;
    border-radius: 100%;
}

.inv {
    max-width: 600px;
}

@media (max-width: 500px) {
    .inv {
        max-width: 380px;
    }
    }

    @media (max-width: 360px) {
        .inv {
            max-width: 300px;
        }
        }